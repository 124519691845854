import { useBaseStore } from '~/stores/base';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const baseStore = useBaseStore();

  if (baseStore.user?.role !== 'admin') {
    useCookie('redirect', { path: '/', sameSite: 'none' }).value = to.fullPath;
    return navigateTo(`/login?redirectTo=${to.path}`);
  }
});
